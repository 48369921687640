<template>
  <div class="setting-item s-document">
    <esmp-loader v-if="isLoading" fix />
    <validation-provider
      rules="required"
      :name="`«${localSetting.name}»`"
      v-slot="v"
      tag="div"
    >
      <esmp-select2
        v-model="modelValue"
        :options="documents"
        label-prop="name"
        clearable
        placeholder="Выбор шаблона документа"
        :error-message="v.errors[0] || ''"
        :remote-method="debouncedSearchMethod"
        @on-query-change="setQuery"
        @on-select="setSelectedDocument"
        @on-clear="clearQuery"
      />
    </validation-provider>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { SEARCH_DELAY } from '@/constants/search';

export default {
  name: 'SDocument',
  model: {
    prop: 'setting',
    event: 'input',
  },
  props: {
    setting: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      documents: [],
      query: '',
      modelValue: null,
    };
  },
  computed: {
    localSetting: {
      get() {
        return this.setting;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    setQuery(val) {
      this.query = val;
    },
    clearQuery() {
      this.setQuery('');
      this.getDocuments();
    },
    setSelectedDocument(val) {
      this.localSetting.value = String(val.id);
    },
    getDocuments() {
      this.isLoading = true;
      return this.$API.documents.getDocuments({ size: 100, query: this.query })
        .then(({ data }) => {
          this.documents = data.content;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  async created() {
    this.debouncedSearchMethod = debounce(this.getDocuments, SEARCH_DELAY);
    await this.getDocuments();

    if (this.localSetting.value && this.documents.length) {
      this.modelValue = this.documents.find((i) => String(i.id) === this.localSetting.value);
    }
  },
};
</script>
